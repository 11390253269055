<template>
  <div :style="{backgroundColor: `#${color}`}"
       class="divider"></div>
</template>

<script>
export default {
  name: 'Divider',
  props: {
    color: {
      type: String,
    },
  },
};
</script>
